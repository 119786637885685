import { AUTH_GUARD } from '@zeiss/ng-vis-vp-auth/injection-tokens';
import { RULE } from '@zeiss/ng-vis-vp-auth/constants';
import { vpRule } from '@zeiss/ng-vis-vp-auth/types';
import { VisIcon } from '@zeiss/ng-vis-common/types';
const ROUTE_PATHS = {
  _: 'gbp',
  organization: 'organization',
  individual: 'individual',
  metadata: 'metadata',
  manualBroadcast: 'manual-broadcast'
};
const ROUTE_CONFIG = {
  path: ROUTE_PATHS._,
  canActivate: [AUTH_GUARD],
  data: {
    title: 'vp.gbp.routes.title',
    description: 'vp.gbp.routes.desc',
    icon: VisIcon.PROJECT_DETAILS,
    showInMenu: true,
    rule: vpRule({
      rule: RULE.access_gbp,
      required: []
    }),
    routes: [{
      path: ''
    }, {
      path: ROUTE_PATHS.organization,
      canActivate: [AUTH_GUARD],
      data: {
        title: 'vp.gbp.routes.organization.title',
        description: 'vp.gbp.routes.organization.desc',
        icon: VisIcon.INFINITY,
        showInMenu: true,
        hierarchy: 4,
        rule: vpRule({
          rule: RULE.access_gbp,
          required: ['organization']
        })
      }
    }, {
      path: ROUTE_PATHS.individual,
      canActivate: [AUTH_GUARD],
      data: {
        title: 'vp.gbp.routes.individual.title',
        description: 'vp.gbp.routes.individual.desc',
        icon: VisIcon.USER_GROUP,
        showInMenu: true,
        hierarchy: 3,
        rule: vpRule({
          rule: RULE.access_gbp,
          required: ['individual']
        })
      }
    }, {
      path: ROUTE_PATHS.manualBroadcast,
      canActivate: [AUTH_GUARD],
      data: {
        title: 'vp.gbp.routes.manualBroadcast.title',
        description: 'vp.gbp.routes.manualBroadcast.desc',
        icon: VisIcon.SEND_MESSAGE,
        showInMenu: true,
        hierarchy: 2,
        rule: vpRule({
          rule: RULE.access_gbp,
          required: ['manual_broadcast']
        })
      }
    }, {
      path: ROUTE_PATHS.metadata,
      canActivate: [AUTH_GUARD],
      data: {
        title: 'vp.gbp.routes.metadata.title',
        description: 'vp.gbp.routes.metadata.desc',
        icon: VisIcon.SYSTEM_PARAMETERS,
        showInMenu: true,
        hierarchy: 1,
        rule: vpRule({
          rule: RULE.access_gbp,
          required: ['metadata']
        })
      }
    }]
  }
};

/**
 * Generated bundle index. Do not edit.
 */

export { ROUTE_CONFIG, ROUTE_PATHS };
